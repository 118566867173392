import { ApolloError, QueryResult } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'

import {
  Exact,
  MeQuery,
  useMeLazyQuery,
  UserFragment,
} from '../generated/graphql'
import { supabaseClient } from '@/lib/supabase'
import { Session } from '@supabase/supabase-js'
import { redirectToLogin } from '@/lib/auth'

interface AuthContextType {
  session: Session | null
  me: UserFragment | null
  meLoading: boolean
  meError?: ApolloError
  hasInit: boolean
  refetchMe: () => Promise<
    QueryResult<
      MeQuery,
      Exact<{
        [key: string]: never
      }>
    >
  >
}

const AuthContext = React.createContext<AuthContextType>(null!)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [me, setMe] = useState<UserFragment | null>(null)
  const [session, setSession] = useState<Session | null>(null)
  const [hasInit, setHasInit] = useState(false)

  const [getMe, { loading: meLoading, error: meError }] = useMeLazyQuery()

  const refetchMe = useCallback(async () => {
    const response = await getMe()
    const me = response.data?.me

    setMe(me ?? null)
    return response
  }, [getMe])

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange(async (event, session) => {
      setSession(session)

      switch (event) {
        case 'INITIAL_SESSION':
          if (session) {
            await refetchMe()
          }

          setHasInit(true)
          break
        case 'SIGNED_IN':
        case 'USER_UPDATED':
          // refetch gql user
          refetchMe()
          break
        case 'SIGNED_OUT':
          // reset auth state
          setMe(null)
          setSession(null)
          break
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        session,
        me,
        meLoading,
        meError,
        refetchMe,
        hasInit,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}
