'use client'

import classNames from 'classnames'
import * as React from 'react'
import { Size } from '@/types/components'
import SpinnerSvg from '@/components/svgs/Spinner'

export default function Spinner({ size }: { size: Size }) {
  return (
    <SpinnerSvg
      className={classNames(
        size === 'xxlarge' && 'h-14 w-14',
        size === 'large' && 'h-8 w-8',
        size === 'medium' && 'h-6 w-6',
        size === 'small' && 'h-4 w-4',
      )}
    />
  )
}
