type Route = {
  path: string
  name: string
  href: string
}

const ROUTES: Record<string, Route> = {
  HOME: {
    path: '',
    name: 'Home',
    href: '',
  },
  RESUME_REVIEW: {
    path: 'resume-review',
    name: 'Home',
    href: '',
  },
  SIGNUP: {
    path: 'signup',
    name: 'Sign Up',
    href: '',
  },
  LOGIN: {
    path: 'login',
    name: 'Login',
    href: '',
  },
  FORGOT_PASSWORD: {
    path: 'forgot-password',
    name: 'Forgot Password',
    href: '',
  },
  SIGNOUT: {
    path: 'signout',
    name: 'Sign Out',
    href: '',
  },
  VERIFY: {
    path: 'verify',
    name: 'Mentor verify',
    href: '/dashboard/verify',
  },
  TERMS_PRIVACY: {
    path: 'terms',
    name: 'Terms and Privacy',
    href: '/privacy-policy',
  },
  DASHBOARD: {
    path: 'dashboard',
    name: 'Dashboard',
    href: '',
  },
  MY_REQUESTS: {
    path: 'my-requests',
    name: 'My Requests',
    href: '/dashboard/my-requests',
  },
  NEW_REQUEST: {
    path: 'new',
    name: 'New Request',
    href: '/dashboard/my-requests/new',
  },
  MY_REFERRALS: {
    path: 'my-referrals',
    name: 'My Referrals',
    href: '/dashboard/my-referrals',
  },
  REFERRAL_REQUESTS: {
    path: 'referrals-requests',
    name: 'Referral Requests',
    href: '/dashboard/referral-requests',
  },
  BECOME_MENTOR: {
    path: 'become-mentor',
    name: 'Become a mentor',
    href: '/dashboard/become-mentor',
  },
  SETTINGS: {
    path: 'settings',
    name: 'Account',
    href: '/dashboard/settings',
  },
  SETTINGS_BILLING: {
    path: 'billing',
    name: 'Billing',
    href: '/dashboard/settings/billing',
  },
  SETTINGS_MENTORSHIP: {
    path: 'mentorship',
    name: 'Mentorship',
    href: '/dashboard/settings/mentorship',
  },
  ADMIN: {
    path: 'admin',
    name: 'Admin',
    href: '/dashboard/admin',
  },
}
for (const route of Object.values(ROUTES)) {
  if (!route.href) route.href = `/${route.path}`
}

export default ROUTES
