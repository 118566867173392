import { HomeCompanyFragment } from '@/generated/graphql'

export const companiesToOptions = (
  companies?: HomeCompanyFragment[] | null,
) => {
  return companies?.map((company) => ({
    label: company.name,
    value: company.id,
  }))
}
