import { createClient as SupabaseCreateClient } from '@supabase/supabase-js'

export const createClient = () => {
  return SupabaseCreateClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  )
}

export const supabaseClient = createClient()
