'use client'

import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useState } from 'react'
import { Drawer, DrawerContent, DrawerTrigger } from './drawer'
import useMediaQuery from '@/hooks/useMediaQuery'

export type ComboboxOption = {
  value: string
  label: string
}

export type ComboboxProps = {
  options?: ComboboxOption[]
  isLoading?: boolean
  thing?: string
  value: string
  onChange: (value: string) => void
}

const Combobox: React.FC<ComboboxProps> = (props: ComboboxProps) => {
  const { options, isLoading, value } = props
  const thing = props.thing ?? 'option'
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 768px)')

  const selectButton = (
    <Button
      variant="outline"
      role="combobox"
      className={cn(
        'w-full justify-between',
        !value && 'text-muted-foreground',
      )}
      isLoading={isLoading}
    >
      {value
        ? options?.find((option) => option.value === value)?.label
        : `Select a ${thing}`}
      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
    </Button>
  )

  if (isLoading) {
    return selectButton
  }

  const optionsList = (
    <ComboboxOptionsList thing={thing} setOpen={setOpen} {...props} />
  )

  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{selectButton}</PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">
          {optionsList}
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>{selectButton}</DrawerTrigger>
      <DrawerContent>
        <div className="mt-4 border-t">{optionsList}</div>
      </DrawerContent>
    </Drawer>
  )
}

function ComboboxOptionsList({
  thing,
  value,
  options,
  setOpen,
  onChange,
}: {
  setOpen: (open: boolean) => void
} & ComboboxProps) {
  const filterTest = `Filter ${thing}...`
  if (!options || options.length === 0) {
    return (
      <Command>
        <CommandInput placeholder={filterTest} />
        <CommandList>
          <CommandEmpty>No options available.</CommandEmpty>
        </CommandList>
      </Command>
    )
  }

  return (
    <Command>
      <CommandInput placeholder={filterTest} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {options.map((option) => (
            <CommandItem
              keywords={[option.label]}
              key={option.value}
              value={option.value}
              onSelect={(value) => {
                onChange(
                  options.find((option) => option.value === value)?.value ?? '',
                )
                setOpen(false)
              }}
            >
              <Check
                className={cn(
                  'mr-2 h-4 w-4',
                  option.value === value ? 'opacity-100' : 'opacity-0',
                )}
              />
              {option.label}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}

export default Combobox
